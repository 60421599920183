import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/hero'
import Divisions from '../../../components/divisions'

const DivisionsPage = ({ data, location }) => {
  const heroImage = data.heroImage
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"

  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="UNICOM Global divisions"
            alt="office blocks against blue sky"
            subtitle="UNICOM Global consists of more than 50 corporate entities encompassing a wide range of businesses."
            type="root"
          />
        </div>

        <Divisions divisions={data.allContentfulDivision.edges} btnId={btnId} />
      </Layout>
    </>
  )
}
export default DivisionsPage

export function Head({ data, location }) {
  const heroImage = data.heroImage

  return (
    <SEO
      title="UNICOM Global divisions" 
      description="UNICOM Global is comprised of over 50 divisions.  UNICOM's technology divisions are outlined below, categorized by industry type/sector." 
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {  
    allContentfulDivision(
      sort: { fields: [sequence], order: ASC }
      filter: { sector: { ne: "None" } }
    ) {
      edges {
        node {
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90) 
              }
            }
          }
          sector: tag
          sectorDescription
          name
          url
        }
      }
    }
    heroImage: file(name: { eq: "divisions-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`